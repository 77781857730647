import React from 'react'
import './Widget.css'
const Widget = () => {
	return (
		<>
			<div style={{ position: 'relative', width: '100%', height: '80px', overflow: 'hidden' }}>
				<iframe
					style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%' }}
					src="https://zrzutka.pl/54yhnm/widget/24"
					frameBorder="0"
					scrolling="no"
					title="Zrzutka Widget"></iframe>
			</div>
			<p className="zrzutka-p">Jeśli chcesz mi pomóc w rozwijaniu tego projektu</p>
		</>
	)
}

export default Widget
