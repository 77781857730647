// src/i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// Import tłumaczeń
import translationEN from './locales/en/translation.json'
import translationPL from './locales/pl/translation.json'
import translationFR from './locales/fr/translation.json' // Dodane
import translationES from './locales/es/translation.json' // Dodane

const resources = {
	en: {
		translation: translationEN,
	},
	pl: {
		translation: translationPL,
	},
	fr: {
		translation: translationFR,
	},
	es: {
		translation: translationES,
	},
}

i18n
	.use(LanguageDetector) // Automatyczne wykrywanie języka
	.use(initReactI18next) // Integracja z React
	.init({
		resources,
		fallbackLng: 'pl', // Domyślny język, jeśli nie wykryto lub nie ma tłumaczenia
		interpolation: {
			escapeValue: false, // React sam zabezpiecza przed XSS
		},
		detection: {
			// Opcjonalne: Dostosowanie detekcji języka
			order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
			caches: ['cookie'],
		},
		react: {
			useSuspense: false,
		},
	})

export default i18n
