// src/components/Misc/Home/Home.js
import React, { Suspense, lazy, useState, useEffect } from 'react'
import './Home.css'
import screenPC from '../../../assets/screenPcOpt.webp'
import screenMobile from '../../../assets/screenMobileOpt.webp'
import Logo from '../../../assets/logo.png'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
// Lazy-loaded components
const Footer = lazy(() => import('../../UI/Footer/Footer'))
const HowItWorks = lazy(() => import('../How/How'))
const Navbar = lazy(() => import('../../UI/Navbar/Navbar'))
const UserStats = lazy(() => import('../../User/UserStats/UserStats'))
const Widget = lazy(() => import('../widget/Widget'))

const Home = () => {
	const { t } = useTranslation()
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768)
		}

		window.addEventListener('resize', handleResize)

		// Cleanup the event listener on component unmount
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<>
			<Suspense fallback={null}>
				<UserStats />
				<Navbar />
			</Suspense>
			<header>
				<div className="header_text">
					<img src={Logo} className="header_logo" alt="Logo" />
					<h1>
						FindAlike.<span>Me</span>
					</h1>
				</div>
				<img
					className="header_img"
					src={isMobile ? screenMobile : screenPC}
					alt={isMobile ? 'Mobile Screen' : 'PC Screen'}
				/>
			</header>

			<Suspense fallback={null}>
				<HowItWorks />
			</Suspense>
			<div className="cta-container">
				<Link to="/register">
					<button className="call-to-action-btn">{t('call_to_action.start_now')}</button>
				</Link>
			</div>
			<Suspense fallback={null}>
				<Footer />
			</Suspense>
		</>
	)
}

export default React.memo(Home)
