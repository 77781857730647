import React from 'react'
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
	return (
		<div className="privacy-policy-container">
			<h1 className="privacy-policy-title">Polityka Prywatności</h1>

			<p className="privacy-policy-updated">
				<strong>Data ostatniej aktualizacji:</strong> 2024-04-27
			</p>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">1. Informacje Ogólne</h2>
				<p>
					Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych użytkowników
					korzystających z serwisu{' '}
					<a href="https://findalike.me" className="policy-link">
						https://findalike.me
					</a>{' '}
					(„Serwis”). Administratorem danych osobowych jest:
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">2. Administrator Danych</h2>
				<p>
					<strong>Dane Administratora:</strong>
					<br />
					Antoni Bartłomiej Blonkowski BLONEKDEV
					<br />
					Adres: Iłowo-Osada Mławka 25, 13-240 Iłowo-Osada, Polska, Mazowieckie
					<br />
					NIP: 571-172-78-74
					<br />
					Email:{' '}
					<a href="mailto:findalikeme@gmail.com" className="policy-link">
						findalikeme@gmail.com
					</a>
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">3. Gromadzenie Danych Osobowych</h2>
				<p>Serwis gromadzi następujące dane osobowe:</p>
				<ul className="policy-list">
					<li>Adres email</li>
					<li>Nazwa użytkownika</li>
					<li>Hasło (przechowywane w formie hashowanej)</li>
					<li>Dane dotyczące korzystania z Serwisu (np. logi serwera, adres IP, typ przeglądarki)</li>
					<li>Informacje dostarczone przez użytkowników podczas korzystania z funkcji czatu</li>
				</ul>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">4. Cele Przetwarzania Danych</h2>
				<p>Państwa dane osobowe są przetwarzane w następujących celach:</p>
				<ul className="policy-list">
					<li>Rejestracja i zarządzanie kontem użytkownika</li>
					<li>
						Umożliwienie korzystania z funkcjonalności czatu w celu opisywania problemów oraz wyszukiwania innych
						użytkowników o podobnych problemach
					</li>
					<li>Personalizacja doświadczeń użytkownika w Serwisie</li>
					<li>Utrzymanie bezpieczeństwa i integralności Serwisu</li>
					<li>Analiza i poprawa funkcjonalności Serwisu</li>
					<li>Komunikacja z użytkownikami w sprawach związanych z Serwisem</li>
				</ul>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">5. Podstawy Prawne Przetwarzania Danych</h2>
				<p>Przetwarzanie danych osobowych odbywa się na podstawie następujących podstaw prawnych:</p>
				<ul className="policy-list">
					<li>Art. 6 ust. 1 lit. b RODO – niezbędność do wykonania umowy, której stroną jest użytkownik</li>
					<li>
						Art. 6 ust. 1 lit. f RODO – prawnie uzasadniony interes administratora, jakim jest świadczenie i rozwój
						usług Serwisu
					</li>
					<li>Art. 6 ust. 1 lit. a RODO – zgoda użytkownika (w przypadkach, gdy przetwarzanie danych wymaga zgody)</li>
				</ul>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">6. Przechowywanie Danych</h2>
				<p>
					Państwa dane osobowe są przechowywane w bazie danych MongoDB przez okres niezbędny do realizacji celów, dla
					których zostały zebrane, chyba że dłuższy okres jest wymagany lub dozwolony przez prawo.
				</p>
				<p>
					Użytkownik ma prawo do usunięcia swojego konta w dowolnym momencie. W przypadku usunięcia konta wszystkie dane
					użytkownika zostaną trwale usunięte z naszej bazy danych, chyba że istnieje prawny obowiązek ich
					przechowywania.
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">7. Przekazywanie Danych Osobowych</h2>
				<div className="subsection">
					<h3 className="subsection-title">7.1. Odbiorcy Danych</h3>
					<p>Państwa dane osobowe mogą być przekazywane następującym kategoriom odbiorców:</p>
					<ul className="policy-list">
						<li>Dostawcom usług IT, którzy wspierają funkcjonowanie Serwisu</li>
						<li>Podmiotom świadczącym usługi analityczne i marketingowe</li>
						<li>Usługodawcom przetwarzającym dane w imieniu Administratora</li>
					</ul>
				</div>
				<div className="subsection">
					<h3 className="subsection-title">7.2. Przekazywanie Danych za Granicę</h3>
					<p>
						Państwa dane osobowe mogą być przekazywane do państw trzecich, które zapewniają odpowiedni poziom ochrony
						danych osobowych zgodnie z decyzją Komisji Europejskiej lub w przypadkach określonych przepisami prawa.
					</p>
				</div>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">8. Prawa Użytkowników</h2>
				<p>Użytkownik ma prawo do:</p>
				<ul className="policy-list">
					<li>Dostępu do swoich danych osobowych</li>
					<li>Żądania sprostowania nieprawidłowych danych</li>
					<li>Żądania usunięcia danych („prawo do bycia zapomnianym”)</li>
					<li>Ograniczenia przetwarzania danych</li>
					<li>Przenoszenia danych</li>
					<li>Wniesienia sprzeciwu wobec przetwarzania danych</li>
					<li>
						Wycofania zgody w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na
						podstawie zgody przed jej wycofaniem
					</li>
				</ul>
				<p>
					W celu realizacji powyższych praw, prosimy o kontakt na adres email:{' '}
					<a href="mailto:findalikeme@gmail.com" className="policy-link">
						findalikeme@gmail.com
					</a>
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">9. Bezpieczeństwo Danych</h2>
				<p>
					Stosujemy odpowiednie środki techniczne i organizacyjne, aby zapewnić bezpieczeństwo Państwa danych osobowych,
					w tym:
				</p>
				<ul className="policy-list">
					<li>Szyfrowanie haseł użytkowników za pomocą bezpiecznych algorytmów haszujących</li>
					<li>Zabezpieczenia bazy danych MongoDB przed nieautoryzowanym dostępem</li>
					<li>Regularne aktualizacje oprogramowania i systemów zabezpieczeń</li>
					<li>Monitorowanie systemów w celu wykrywania i reagowania na potencjalne zagrożenia</li>
				</ul>
				<p>
					Mimo zastosowanych środków bezpieczeństwa, żadna metoda transmisji danych przez Internet ani metoda
					przechowywania danych nie jest w 100% bezpieczna. Dlatego Administrator nie może zagwarantować absolutnego
					bezpieczeństwa Państwa danych.
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">10. Pliki Cookies</h2>
				<p>
					Serwis może wykorzystywać pliki cookies w celu poprawy funkcjonalności oraz analizowania ruchu na stronie.
					Pliki cookies to niewielkie pliki tekstowe przechowywane na urządzeniu użytkownika przez przeglądarkę
					internetową.
				</p>
				<div className="subsection">
					<h3 className="subsection-title">10.1. Rodzaje Plików Cookies</h3>
					<ul className="policy-list">
						<li>
							<strong>Cookies niezbędne:</strong> Konieczne do prawidłowego funkcjonowania Serwisu.
						</li>
						<li>
							<strong>Cookies analityczne:</strong> Umożliwiają analizę sposobu korzystania z Serwisu.
						</li>
						<li>
							<strong>Cookies funkcjonalne:</strong> Umożliwiają zapamiętanie preferencji użytkownika.
						</li>
					</ul>
				</div>
				<div className="subsection">
					<h3 className="subsection-title">10.2. Zarządzanie Plikami Cookies</h3>
					<p>
						Użytkownik może zarządzać ustawieniami plików cookies w swojej przeglądarce, w tym je blokować lub usuwać.
						Należy jednak pamiętać, że wyłączenie plików cookies może wpłynąć na funkcjonalność Serwisu.
					</p>
				</div>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">11. Wykorzystanie Usług Stron Trzecich</h2>
				<p>
					Serwis korzysta z usług OpenAI w celu dopasowywania opisów problemów do wybranych kategorii. Dane przetwarzane
					przez OpenAI są wykorzystywane wyłącznie do realizacji funkcjonalności Serwisu i są zgodne z polityką
					prywatności OpenAI.
				</p>
				<p>
					Ponadto, możemy korzystać z usług analitycznych, reklamowych i innych usług stron trzecich, które mogą zbierać
					dane osobowe zgodnie ze swoimi własnymi politykami prywatności.
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">12. Przetwarzanie Danych Uzupełniających</h2>
				<p>
					W ramach korzystania z funkcji czatu, użytkownicy mogą dostarczać dodatkowe informacje dotyczące ich
					problemów. Te dane są przetwarzane w celu umożliwienia dopasowania użytkowników o podobnych problemach oraz
					poprawy jakości świadczonych usług.
				</p>
				<p>
					Administrator nie kontroluje treści publikowanych przez użytkowników i nie ponosi odpowiedzialności za te
					treści. Użytkownicy są odpowiedzialni za treści, które publikują, a Administrator nie odpowiada za ewentualne
					negatywne skutki wynikające z konwersacji między użytkownikami.
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">13. Zgłaszanie Naruszeń Ochrony Danych</h2>
				<p>
					W przypadku podejrzenia naruszenia ochrony danych osobowych, użytkownik ma prawo zgłosić to odpowiednim
					organom nadzorczym, w Polsce jest to <strong>Prezes Urzędu Ochrony Danych Osobowych</strong>.
				</p>
				<p>
					<strong>Dane kontaktowe do organu nadzorczego:</strong>
					<br />
					Prezes Urzędu Ochrony Danych Osobowych
					<br />
					ul. Stawki 2
					<br />
					00-193 Warszawa
					<br />
					Email:{' '}
					<a href="mailto:president@uodo.gov.pl" className="policy-link">
						president@uodo.gov.pl
					</a>
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">14. Zmiany w Polityce Prywatności</h2>
				<p>
					Administrator zastrzega sobie prawo do wprowadzania zmian w niniejszej Polityce Prywatności. Wszystkie zmiany
					będą publikowane na tej stronie, a ich data wejścia w życie będzie aktualizowana.
				</p>
				<p>Zaleca się regularne zapoznawanie się z Polityką Prywatności, aby być na bieżąco z ewentualnymi zmianami.</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">15. Kontakt</h2>
				<p>
					W przypadku pytań dotyczących Polityki Prywatności, prosimy o kontakt na adres email:{' '}
					<a href="mailto:findalikeme@gmail.com" className="policy-link">
						findalikeme@gmail.com
					</a>
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">16. Dane Kontaktowe Inspektora Ochrony Danych</h2>
				<p>
					Jeśli masz pytania dotyczące przetwarzania Twoich danych osobowych lub chcesz skorzystać ze swoich praw,
					możesz skontaktować się z Administratorem danych:
				</p>
				<p>
					Email:{' '}
					<a href="mailto:findalikeme@gmail.com" className="policy-link">
						findalikeme@gmail.com
					</a>
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">17. Automatyczne Decyzje i Profilowanie</h2>
				<p>
					Serwis może wykorzystywać automatyczne procesy decyzyjne, w tym profilowanie, w celu dopasowywania
					użytkowników do innych osób o podobnych problemach. Decyzje podejmowane automatycznie nie mają na celu
					wpływania na prawa i wolności użytkowników.
				</p>
				<p>
					Użytkownik ma prawo do uzyskania informacji o logice stosowanej przy takich procesach oraz o znaczeniu i
					przewidywanych konsekwencjach takich przetwarzania.
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">18. Przechowywanie Informacji o Sesjach</h2>
				<p>
					Podczas korzystania z Serwisu, mogą być przechowywane informacje o sesjach użytkowników w celu zapewnienia
					płynnego funkcjonowania czatu oraz umożliwienia dalszej komunikacji między użytkownikami.
				</p>
				<p>
					Informacje te są przechowywane tylko na czas trwania sesji i są usuwane po jej zakończeniu, chyba że
					użytkownik zdecyduje inaczej poprzez usunięcie swojego konta.
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">19. Minimalizacja Danych</h2>
				<p>
					Administrator dąży do minimalizacji przetwarzania danych osobowych, zbierając tylko te dane, które są
					niezbędne do realizacji celów, dla których zostały zebrane.
				</p>
				<p>
					Niezbędność przetwarzania danych jest regularnie oceniana w celu zapewnienia zgodności z zasadą minimalizacji
					danych określoną w RODO.
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">20. Powiadomienia o Naruszeniach Danych</h2>
				<p>
					W przypadku stwierdzenia naruszenia ochrony danych osobowych, Administrator niezwłocznie podejmie odpowiednie
					kroki w celu zminimalizowania jego skutków. W przypadkach, gdy naruszenie może skutkować ryzykiem dla praw i
					wolności osób fizycznych, Administrator powiadomi o tym odpowiednie organy nadzorcze oraz, w razie potrzeby,
					osoby, których dane dotyczą.
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">21. Zasady Ogólne</h2>
				<p>
					Niniejsza Polityka Prywatności jest integralną częścią regulaminu Serwisu. Wszelkie postanowienia Regulaminu,
					które są sprzeczne z Polityką Prywatności, pozostają w mocy.
				</p>
				<p>
					W przypadku jakichkolwiek sprzeczności między różnymi wersjami Polityki Prywatności, obowiązuje wersja
					najnowsza.
				</p>
			</section>

			<hr className="divider" />

			<section className="privacy-policy-section">
				<h2 className="section-title">22. Jurysdykcja</h2>
				<p>
					Niniejsza Polityka Prywatności jest regulowana i interpretowana zgodnie z prawem polskim. Wszelkie spory
					związane z przetwarzaniem danych osobowych będą rozstrzygane przez właściwe sądy powszechne w Polsce.
				</p>
			</section>
		</div>
	)
}

export default PrivacyPolicy
