// src/components/User/FriendsList/FriendsList.js

import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import './FriendsList.css'
import { ReactComponent as LogoutIcon } from '../../../assets/logout.svg'
import { ReactComponent as EditProfileIcon } from '../../../assets/edit-profile.svg'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../../context/AuthContext'
import Button from '../../Misc/widget/Button'
import { useTranslation } from 'react-i18next'

const FriendsList = ({
	selectedFriend,
	onSelectFriend,
	onFindNew,
	isSearching,
	setShowWaitingRoom,
	messages,
	onlineStatuses,
}) => {
	const { t } = useTranslation() // Dodano useTranslation
	const [friends, setFriends] = useState([])
	const [unreadCounts, setUnreadCounts] = useState({})
	const { user, logoutAndRedirect } = useAuth()
	const navigate = useNavigate()
	const [lastMessages, setLastMessages] = useState({})
	const [lastMessageDates, setLastMessageDates] = useState({})

	useEffect(() => {
		const fetchFriends = async () => {
			try {
				// Pobieranie rzeczywistych danych o znajomych
				const { data } = await axiosInstance.get('/friends')
				setFriends(data)

				const initialLastMessages = {}
				const initialLastMessageDates = {}
				for (const friend of data) {
					const response = await axiosInstance.get(`/messages/last?user1=${user._id}&user2=${friend._id}`)
					const lastMessage = response.data

					// Sprawdzenie, czy lastMessage istnieje i ma właściwość 'content'
					if (lastMessage && lastMessage.content) {
						initialLastMessages[friend._id] =
							lastMessage.content.length > 6 ? lastMessage.content.substring(0, 6) + '...' : lastMessage.content
						initialLastMessageDates[friend._id] = new Date(lastMessage.createdAt)
					} else {
						initialLastMessages[friend._id] = '' // Możesz ustawić domyślną wartość
						initialLastMessageDates[friend._id] = new Date(0) // Najstarsza możliwa data
					}
				}
				setLastMessages(initialLastMessages)
				setLastMessageDates(initialLastMessageDates)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					console.error('Nieautoryzowany dostęp, wylogowywanie...')
					logoutAndRedirect()
				} else {
					console.error('Błąd podczas pobierania znajomych:', error)
				}
			}
		}

		if (user) {
			fetchFriends()
		}
	}, [user, logoutAndRedirect])

	useEffect(() => {
		if (messages.length > 0) {
			const latestMessage = messages[messages.length - 1]
			const friendId = latestMessage.sender === user._id ? latestMessage.receiver : latestMessage.sender

			setLastMessages(prev => ({
				...prev,
				[friendId]:
					latestMessage.content.length > 6 ? latestMessage.content.substring(0, 6) + '...' : latestMessage.content,
			}))

			setLastMessageDates(prev => ({
				...prev,
				[friendId]: new Date(latestMessage.createdAt),
			}))
		}
	}, [messages, user._id])

	const sortedFriends = friends.slice().sort((a, b) => {
		const dateA = lastMessageDates[a._id] || new Date(0)
		const dateB = lastMessageDates[b._id] || new Date(0)
		return dateB - dateA
	})

	const handleLogout = () => {
		logoutAndRedirect()
		toast.success(t('friends_list.logout_success')) // Użycie t()
	}

	const handleSelectFriend = friend => {
		setUnreadCounts(prev => ({
			...prev,
			[friend._id]: 0,
		}))
		onSelectFriend(friend)
	}

	const handleFindNewClick = () => {
		if (!isSearching) {
			onFindNew()
		} else {
			setShowWaitingRoom(true)
		}
	}

	return (
		<div className="friends-list__container">
			<div className="friends-list__profile-header">
				<div className="friends-list__profile-info" onClick={() => navigate('/edit-profile')}>
					<img src={user.profilePicture} alt={user.name} className="friends-list__profile-picture no-border" />
					<div className="friends-list__user-details">
						<span className="friends-list__user-name">{user.name}</span>
						<EditProfileIcon className="friends-list__edit-profile-icon" />
					</div>
				</div>
				<LogoutIcon className="friends-list__logout-icon" onClick={handleLogout} />
			</div>
			<button className="primary__button" onClick={handleFindNewClick}>
				{isSearching ? t('friends_list.searching_friend') : t('friends_list.find_friend')} {/* Użycie t() */}
			</button>
			<h5 className="friends-list__friends__list__title">{t('friends_list.your_friends')}</h5> {/* Użycie t() */}
			<div className="friends-list__scroll-container">
				<ul className="friends-list__list">
					{sortedFriends.length > 0 ? (
						sortedFriends.map(friend => (
							<li
								key={friend._id}
								className={`friends-list__item ${selectedFriend && selectedFriend._id === friend._id ? 'active' : ''}`}
								onClick={() => handleSelectFriend(friend)}>
								<div className="friends-list__item__status">
									<img
										src={friend.profilePicture}
										alt={friend.name}
										className={`friends-list__profile-picture pfp099 ${
											onlineStatuses[friend._id] === 'online' ? 'green-border' : 'gray-border'
										}`}
									/>
									<span
										className={`friends-list__status-icon ${
											onlineStatuses[friend._id] === 'online' ? 'online' : 'offline'
										}`}></span>
								</div>
								<div className="friends-list__details">
									<span className="friends-list__name">{friend.name}</span>
									<span className="friends-list__last-message">{lastMessages[friend._id]}</span>
									{unreadCounts[friend._id] > 0 && (
										<span className="friends-list__unread-count">{unreadCounts[friend._id]}</span>
									)}
								</div>
							</li>
						))
					) : (
						<p className="friends-list-p">{t('friends_list.no_friends')}</p>
					)}
				</ul>
			</div>
		</div>
	)
}

export default FriendsList
