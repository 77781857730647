import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CustomSelect from './CustomSelect'
import './FindNew.css'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const FindNew = ({ onSubmit }) => {
	const { t } = useTranslation()
	const [isDescribeMode, setIsDescribeMode] = useState(false)
	const [category, setCategory] = useState('')
	const [specificIssue, setSpecificIssue] = useState('')
	const [problemDescription, setProblemDescription] = useState('')
	const [ageRange, setAgeRange] = useState('')
	const [friendsAdded, setFriendsAdded] = useState(0)

	// Pobieranie danych użytkownika
	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const { data } = await axios.get(`https://findalike.me/api/auth/user-info`, {
					withCredentials: true,
				})
				setFriendsAdded(data.FriendsAdded)
			} catch (error) {
				console.error(t('findNew.fetchUserDataError'), error)
				toast.error(t('findNew.fetchUserDataError'))
			}
		}
		fetchUserData()
	}, [t])

	const handleCategoryChange = value => {
		setCategory(value)
		setSpecificIssue('')
	}

	const submitHandler = async e => {
		e.preventDefault()

		if (friendsAdded >= 99) {
			toast.error(t('findNew.submitButton.limitReached'))
			return
		}

		if (isDescribeMode) {
			if (!problemDescription.trim() || problemDescription.trim().length < 10 || !ageRange) {
				toast.error(t('findNew.formValidationErrorDescription'))
				return
			}
			try {
				toast.info(t('findNew.searchingFriend'))
				const { data } = await axios.post(
					`https://findalike.me/api/submit-description`,
					{ description: problemDescription, ageRange },
					{ withCredentials: true }
				)
				onSubmit(data)
			} catch (error) {
				if (error.response && error.response.status === 400 && error.response.data.allowCancel) {
					alert(
						`${t('findNew.activeResponseWarning')} ${error.response.data.existingResponse.category}. ${t(
							'findNew.alert_can_cancel'
						)}`
					)
				} else {
					console.error(t('findNew.submitError'), error.response ? error.response.data : error.message)
					toast.error(t('findNew.submitError'))
				}
			}
		} else {
			if (!specificIssue || !category) {
				toast.error(t('findNew.formValidationError'))
				return
			}
			try {
				toast.info(t('findNew.searchingFriend'))
				const { data } = await axios.post(
					`https://findalike.me/api/submit-response`,
					{ category, specificIssue },
					{ withCredentials: true }
				)
				onSubmit(data)
			} catch (error) {
				if (error.response && error.response.status === 400 && error.response.data.allowCancel) {
					alert(
						`${t('findNew.activeResponseWarning')} ${error.response.data.existingResponse.category}. ${t(
							'findNew.alert_can_cancel'
						)}`
					)
				} else {
					console.error(t('findNew.submitError'), error.response ? error.response.data : error.message)
					toast.error(t('findNew.submitError'))
				}
			}
		}
	}

	// Opcje wiekowe
	const ageOptions = ['0-18', '18+']
	// Ładowanie opcji problemów z tłumaczenia
	const issueOptions = t('findNew.categories', { returnObjects: true })

	const isSubmitDisabled = isDescribeMode
		? !problemDescription.trim() || problemDescription.trim().length < 10 || !ageRange
		: !category || !specificIssue

	return (
		<div className="find-new__container">
			{/* Warunkowe renderowanie odznaki tylko w trybie opisowym */}
			{isDescribeMode && (
				<div className="beta-badge-container">
					<span className="beta-badge">BETA</span>
					<span className="beta-info">
						{t('findNew.betaInfo', {
							defaultValue:
								'Uwaga! Funkcja jest w wersji beta i może działać niestabilnie. Wyniki mogą czasami być nieprecyzyjne.',
						})}
					</span>
				</div>
			)}
			<form className="find-new__form" onSubmit={submitHandler}>
				<div className={`choice__findnew ${isDescribeMode ? 'active-description' : 'active-choice'}`}>
					<button className={!isDescribeMode ? 'active' : ''} onClick={() => setIsDescribeMode(false)} type="button">
						{t('findNew.labels.selectMode')}
					</button>
					<button className={isDescribeMode ? 'active' : ''} onClick={() => setIsDescribeMode(true)} type="button">
						{t('findNew.labels.describeMode')}
					</button>
				</div>

				{isDescribeMode ? (
					<>
						<label>
							{t('findNew.labels.selectAgeRange')}
							<CustomSelect
								options={ageOptions}
								value={ageRange}
								onChange={setAgeRange}
								placeholder={t('findNew.placeholders.selectAgeRange')}
							/>
						</label>

						<label>
							{t('findNew.labels.describeYourProblem')}
							<textarea
								value={problemDescription}
								onChange={e => setProblemDescription(e.target.value)}
								placeholder={t('findNew.placeholders.describeYourProblem')}
							/>
							{problemDescription && problemDescription.trim().length < 10 && (
								<span className="error-message-findnew">{t('findNew.formValidationDescriptionLength')}</span>
							)}
						</label>
					</>
				) : (
					<>
						<label>
							{t('findNew.labels.selectCategory')}
							<CustomSelect
								options={Object.keys(issueOptions)}
								value={category}
								onChange={handleCategoryChange}
								placeholder={t('findNew.placeholders.category')}
							/>
						</label>

						{category && (
							<label>
								{t('findNew.labels.selectSpecificIssue')}
								<CustomSelect
									options={issueOptions[category]}
									value={specificIssue}
									onChange={setSpecificIssue}
									placeholder={t('findNew.placeholders.specificIssue')}
								/>
							</label>
						)}
					</>
				)}

				<button type="submit" className="secondary__button" disabled={isSubmitDisabled}>
					{t('findNew.submitButton.default')}
				</button>
				<span className="close-info">{t('findNew.closeInfo')}</span>
			</form>
		</div>
	)
}

export default FindNew
