// src/components/WaitingRoom/WaitingRoom.js
import React from 'react'
import axios from 'axios'
import './WaitingRoom.css'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const WaitingRoom = ({ onCancelSearch }) => {
	const { t } = useTranslation()

	const handleCancelSearch = async () => {
		try {
			await axios.delete(`https://findalike.me/api/cancel-search`, { withCredentials: true })
			toast.success(t('waiting_room.cancel_success'))
			onCancelSearch() // Notify parent to update the state
		} catch (error) {
			console.error('Error cancelling search', error)
			toast.error(t('waiting_room.cancel_error'))
		}
	}

	return (
		<div className="waiting-room__container">
			<div className="waiting-room__loader"></div>
			<h2>{t('waiting_room.waiting_for_person')}</h2>
			<button className="secondary__button" onClick={handleCancelSearch}>
				{t('waiting_room.cancel_search')}
			</button>
			<span className="close-info">{t('waiting_room.info_email')}</span>
			<span className="close-info">{t('waiting_room.info_click_outside')}</span>
		</div>
	)
}

export default WaitingRoom
