// src/components/ChatWindow/ChatWindow.js
import React, { useState, useEffect, useRef } from 'react'
import io from 'socket.io-client'
import { useAuth } from '../../../context/AuthContext'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import './ChatWindow.css'
import './Messages.css'
import { ReactComponent as DeleteUserIcon } from '../../../assets/deleteuser.svg'
import { ReactComponent as OptionsIcon } from '../../../assets/options.svg'
import { ReactComponent as ReplyIcon } from '../../../assets/reply.svg'
import { ReactComponent as SendIcon } from '../../../assets/sendicon.svg'
import { ReactComponent as EditIcon } from '../../../assets/pen-solid.svg'
import { ReactComponent as DeleteIconSVG } from '../../../assets/trash-solid.svg'
import { ReactComponent as InfoIcon } from '../../../assets/circle-question-regular.svg'
import { ReactComponent as ArrowLeftIcon } from '../../../assets/chevron-left-solid.svg'
import { ReactComponent as FriendsImage } from '../../../assets/Add friends-pana.svg'

import ConfirmationDialog from '../../UI/ConfirmationDialog/ConfirmationDialog'

const socket = io(`https://findalike.me`, {
	withCredentials: true,
	extraHeaders: {
		'my-custom-header': 'abcd',
	},
})
const languageFlags = {
	pl: '🇵🇱',
	en: '🇬🇧',
	fr: '🇫🇷',
	es: '🇪🇸',
	// Dodaj inne języki i ich flagi tutaj
}
const ChatWindow = ({ selectedFriend, toggleFriendInfo, toggleSidebar }) => {
	const { t, i18n } = useTranslation() // Dodano i18n
	const { user } = useAuth()
	const [messages, setMessages] = useState([])
	const [message, setMessage] = useState('')
	const [replyTo, setReplyTo] = useState(null)
	const [showOptions, setShowOptions] = useState(null)
	const [showTopOptions, setShowTopOptions] = useState(false)
	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
	const messagesEndRef = useRef(null)
	const optionsRef = useRef(null)
	const textareaRef = useRef(null)
	const dropdownRef = useRef(null)

	const handleInputChange = e => {
		setMessage(e.target.value)
		textareaRef.current.style.height = '53px' // Reset textarea height
		let scHeight = e.target.scrollHeight
		textareaRef.current.style.height = `${scHeight}px` // Dynamic height adjustment
	}

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
	}

	const handleReply = message => {
		if (!message.isDeleted) {
			setReplyTo(message)
			scrollToBottom()
		}
	}

	useEffect(() => {
		if (selectedFriend) {
			const fetchMessages = async () => {
				try {
					const { data } = await axios.get(
						`https://findalike.me/api/messages?user1=${user._id}&user2=${selectedFriend._id}`,
						{ withCredentials: true }
					)
					setMessages(data)
				} catch (error) {
					console.error('Error fetching messages:', error)
				}
			}
			fetchMessages()
		}
	}, [selectedFriend, user._id])

	useEffect(() => {
		socket.emit('join', user._id)

		socket.on('receiveMessage', async message => {
			if (
				selectedFriend &&
				((message.sender === user._id && message.receiver === selectedFriend._id) ||
					(message.receiver === user._id && message.sender === selectedFriend._id))
			) {
				setMessages(prevMessages => {
					const updatedMessages = [...prevMessages, message]
					if (updatedMessages.length > 20) {
						const oldestMessage = updatedMessages[0] // The message to delete

						// Send request to backend to delete the oldest message from the database
						deleteOldestMessage(oldestMessage._id)

						updatedMessages.shift() // Remove the first (oldest) message
					}
					return updatedMessages
				})
				scrollToBottom()
			}
		})

		const deleteOldestMessage = async messageId => {
			try {
				await axios.delete(`https://findalike.me/api/messages/${messageId}`, {
					withCredentials: true,
				})
				console.log('elo')
			} catch (error) {
				console.error('Error deleting the oldest message:', error)
			}
		}
		socket.on('messageEdited', editedMessage => {
			if (selectedFriend) {
				setMessages(prevMessages => prevMessages.map(msg => (msg._id === editedMessage._id ? editedMessage : msg)))
				scrollToBottom()
			}
		})

		socket.on('messageDeleted', deletedMessage => {
			if (selectedFriend) {
				setMessages(prevMessages =>
					prevMessages.map(msg =>
						msg._id === deletedMessage._id
							? { ...msg, isDeleted: true, content: t('chat_window.message_deleted') }
							: msg
					)
				)
				scrollToBottom()
			}
		})

		// Listening to `messagesReset` event
		socket.on('messagesReset', () => {
			;('Received messagesReset event') // Added for debuggingconsole.log
			window.location.reload() // Refresh the page upon receiving the event
		})

		socket.on('friendRemoved', ({ friendId }) => {
			if (selectedFriend && selectedFriend._id === friendId) {
				window.location.reload()
			} else if (user._id === friendId) {
				window.location.reload()
			}
		})

		return () => {
			socket.off('receiveMessage')
			socket.off('messageEdited')
			socket.off('messageDeleted')
			socket.off('messagesReset') // Remove listener on component unmount
			socket.off('friendRemoved')
		}
	}, [user, selectedFriend, t, i18n.language]) // Dodano i18n.language

	useEffect(() => {
		scrollToBottom()
	}, [messages])

	const handleSendMessage = async () => {
		if (selectedFriend && message.trim()) {
			const newMessage = {
				sender: user._id,
				receiver: selectedFriend._id,
				content: message,
				replyTo: replyTo ? replyTo._id : null,
			}

			socket.emit('sendMessage', newMessage)
			setMessage('')
			setReplyTo(null)
			textareaRef.current.style.height = '59px'
		}
	}

	const handleDeleteMessage = async messageId => {
		if (selectedFriend) {
			socket.emit('deleteMessage', { messageId })

			try {
				await axios.delete(`https://findalike.me/api/messages/${messageId}`, {
					withCredentials: true,
				})
			} catch (error) {
				console.error('Error deleting message:', error)
			}
		}
	}

	const handleEditMessage = async (messageId, newContent) => {
		if (selectedFriend) {
			socket.emit('editMessage', { messageId, content: newContent })

			try {
				await axios.put(
					`https://findalike.me/api/messages/${messageId}`,
					{ content: newContent },
					{ withCredentials: true }
				)
			} catch (error) {
				console.error('Error editing message:', error)
			}
		}
	}

	const handleOptionsToggle = messageId => {
		if (showOptions === messageId) {
			setShowOptions(null)
		} else {
			setShowOptions(messageId)
		}
	}

	const handleClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setShowOptions(null)
			setShowTopOptions(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleKeyPress = event => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			handleSendMessage()
		}
	}

	const handleRemoveFriend = async friendId => {
		try {
			await axios.post(`https://findalike.me/api/remove-friend`, { friendId }, { withCredentials: true })
			socket.emit('friendRemoved', { friendId })
			window.location.reload()
		} catch (error) {
			console.error('Error removing friend', error)
		}
	}

	const confirmRemoveFriend = () => {
		setShowConfirmationDialog(true)
	}

	const cancelRemoveFriend = () => {
		setShowConfirmationDialog(false)
	}

	const confirmRemove = () => {
		if (selectedFriend) {
			handleRemoveFriend(selectedFriend._id)
			setShowConfirmationDialog(false)
		}
	}

	const shouldStartNewSeries = (currentMsg, prevMsg) => {
		if (!prevMsg) return true

		if (currentMsg.sender !== prevMsg.sender) {
			return true
		}

		const currentDate = new Date(currentMsg.createdAt)
		const prevDate = new Date(prevMsg.createdAt)

		if (currentDate.getMinutes() !== prevDate.getMinutes()) {
			return true
		}

		return false
	}

	// Function to format date
	const formatMessageDate = messageDate => {
		const now = new Date()
		const date = new Date(messageDate)
		const isToday =
			date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()

		const isCurrentYear = date.getFullYear() === now.getFullYear()

		const datePart = isToday
			? t('chat_window.today')
			: date.toLocaleDateString(i18n.language, {
					year: isCurrentYear ? undefined : 'numeric',
					month: 'long',
					day: 'numeric',
			  })

		const timePart = date.toLocaleTimeString(i18n.language, {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		})

		return `${datePart} • ${timePart}`
	}

	return (
		<div className="chat-window__container">
			{selectedFriend ? (
				<>
					<div className="selcted-friend-top-info">
						<div className="div-aic">
							<ArrowLeftIcon onClick={toggleSidebar} width="15px" className="arrow-left-icon-chat" />
							<img src={selectedFriend.profilePicture} alt="Profile" onClick={toggleFriendInfo} />
							<span onClick={toggleFriendInfo}> {selectedFriend.name}</span>
						</div>
						<div className="div-aic div-asdas">
							<DeleteUserIcon width="20px" onClick={confirmRemoveFriend} className="OptionsIcon__chat" />
							<InfoIcon width="20px" onClick={toggleFriendInfo} className="infoicon" />
						</div>
					</div>
					<h5 className="friends-list__friends__list__title__2">{t('chat_window.chat')}</h5>
					<div className="chat-window__messages-container">
						<div className="bg-pattern"></div>
						{messages.map((msg, index) => {
							const prevMsg = index > 0 ? messages[index - 1] : null
							const nextMsg = index < messages.length - 1 ? messages[index + 1] : null

							const isSeriesStart = shouldStartNewSeries(msg, prevMsg)

							const isLastInSeries =
								!nextMsg ||
								(nextMsg &&
									(nextMsg.sender !== msg.sender ||
										new Date(nextMsg.createdAt).getMinutes() !== new Date(msg.createdAt).getMinutes()))

							const isStandalone = isSeriesStart && isLastInSeries

							const parentClasses = [
								'chat-window__message',
								msg.sender === user._id ? 'chat-window__message-sent' : 'chat-window__message-received',
								msg.isDeleted ? 'chat-window__message-deleted' : '',
								isStandalone ? '' : isSeriesStart ? 'message-series-start' : '',
								!isStandalone && isLastInSeries ? 'message-series-end' : '',
								!isStandalone && !isSeriesStart && !isLastInSeries ? 'message-in-series' : '',
							]
								.filter(Boolean)
								.join(' ')

							return (
								<div key={msg._id} className={parentClasses}>
									{isLastInSeries && msg.sender !== user._id && (
										<img src={selectedFriend.profilePicture} alt="Profile" className="chat-window__profile-picture" />
									)}

									<div className="chat-window__message-content">
										{msg.isDeleted ? t('chat_window.message_deleted') : msg.content}
										{msg.edited && !msg.isDeleted && <span className="chat-window__edited-label">*</span>}
										<div className="chat-window__message-actions">
											{msg.sender === user._id && (
												<>
													{!msg.isDeleted && (
														<button onClick={() => handleOptionsToggle(msg._id)}>
															<OptionsIcon width="5px" />
														</button>
													)}
													{showOptions === msg._id && (
														<div className="chat-window__options-dropdown show" ref={dropdownRef}>
															{/* Opcjonalne przyciski edycji i usuwania */}
															{/* <button onClick={() => handleEditMessage(msg._id, prompt(t('chat_window.edit_prompt'), msg.content))}>
																<EditIcon width="15px" />
															</button>
															<button onClick={() => handleDeleteMessage(msg._id)}>
																<DeleteIconSVG width="15px" />
															</button> */}
															<button onClick={() => handleReply(msg)}>
																<ReplyIcon width="13px" />
															</button>
														</div>
													)}
												</>
											)}
											{msg.sender !== user._id && !msg.isDeleted && (
												<button onClick={() => handleReply(msg)}>
													<ReplyIcon width="13px" />
												</button>
											)}
										</div>
										{msg.replyTo && (
											<div className="chat-window__reply-message">
												{t('chat_window.replying_to')}: {messages.find(m => m._id === msg.replyTo)?.content}
											</div>
										)}
									</div>
									{isStandalone || isLastInSeries ? (
										<div className="chat-window__message-time">{formatMessageDate(msg.createdAt)}</div>
									) : null}
								</div>
							)
						})}
						<div ref={messagesEndRef} />
					</div>
					{replyTo && (
						<div className="chat-window__reply-preview">
							<span>
								{t('chat_window.replying_to')}: <b>{replyTo.content}</b>
							</span>
							<button onClick={() => setReplyTo(null)}>{t('chat_window.cancel_reply')}</button>
						</div>
					)}

					<div className="chat-window__message-input-container">
						<textarea
							ref={textareaRef}
							value={message}
							onChange={handleInputChange}
							onKeyPress={handleKeyPress}
							placeholder={t('chat_window.write_message_placeholder')}
							disabled={!selectedFriend}
							className="chat-window__message-input"
						/>
						<div
							onClick={handleSendMessage}
							className={`chat-window__send-button ${!message.trim() ? 'disabled' : ''}`}
							disabled={!selectedFriend || !message.trim()}>
							<SendIcon height="20px" />
						</div>
					</div>
				</>
			) : (
				<div className="random-class-lool">
					<p>{t('chat_window.select_friend_start_chatting')}</p>
					<FriendsImage className="img-friends" />
					<button onClick={toggleSidebar} className="chat-window__toggle-sidebar-button secondary__button">
						{t('chat_window.list_of_friends')}
					</button>
				</div>
			)}
			{showConfirmationDialog && (
				<ConfirmationDialog
					message={t('chat_window.confirm_remove_friend', { name: selectedFriend.name })}
					onConfirm={confirmRemove}
					onCancel={cancelRemoveFriend}
				/>
			)}
		</div>
	)
}

export default ChatWindow
