// src/components/User/EditProfile/EditProfile.js

import React, { useState, useEffect, useRef } from 'react'
import { useAuth, axiosInstance } from '../../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import './EditProfile.css'
import { toast } from 'react-toastify'
import leoProfanity from 'leo-profanity'
import { useTranslation } from 'react-i18next'
import { FaChevronDown } from 'react-icons/fa' // Import chevron icon
import ConfirmationDialog from '../../UI/ConfirmationDialog/ConfirmationDialog' // Import ConfirmationDialog

const EditProfile = () => {
	const { t, i18n } = useTranslation()
	const { user, setUser } = useAuth()
	const [name, setName] = useState('')
	const [password, setPassword] = useState('')
	const [profilePicture, setProfilePicture] = useState('')
	const [description, setDescription] = useState('')
	const [error, setError] = useState('')
	const [charCount, setCharCount] = useState(0)
	const [nameError, setNameError] = useState('')
	const [passwordStrength, setPasswordStrength] = useState('')
	const [language, setLanguage] = useState('pl')
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [isConfirmationOpen, setIsConfirmationOpen] = useState(false) // State for ConfirmationDialog
	const [nameAvailable, setNameAvailable] = useState(true) // New state for username availability
	const dropdownRef = useRef(null)
	const navigate = useNavigate()

	// Language definitions with flags
	const languages = [
		{ code: 'pl', nativeName: 'Polski', flag: '🇵🇱' },
		{ code: 'en', nativeName: 'English', flag: '🇬🇧' },
		{ code: 'fr', nativeName: 'Français', flag: '🇫🇷' },
		{ code: 'es', nativeName: 'Español', flag: '🇪🇸' },
		// Add more languages as needed
	]

	// Initialize profanity blacklist
	const customBlacklist = ['kurwa']

	useEffect(() => {
		leoProfanity.loadDictionary()
		customBlacklist.forEach(word => leoProfanity.add(word))
	}, [])

	// Initialize profile fields after user data is loaded
	useEffect(() => {
		if (user) {
			setName(user.name)
			setProfilePicture(user.profilePicture)
			setDescription(user.description)
			setCharCount(user.description ? user.description.length : 0)
			setLanguage(user.language || 'pl')
		}
	}, [user])

	// Generate avatar URL when name changes
	useEffect(() => {
		if (name) {
			const avatarUrl = `https://api.dicebear.com/9.x/thumbs/svg?seed=${encodeURIComponent(name)}`
			setProfilePicture(avatarUrl)
		}
	}, [name])

	// Validate password
	const validatePassword = password => {
		let strength = ''
		if (password.length >= 6) {
			if (/[A-Z]/.test(password) && /\d/.test(password)) {
				if (password.length >= 12) {
					strength = t('edit_profile.password_strength_very_strong')
				} else if (password.length >= 8) {
					strength = t('edit_profile.password_strength_strong')
				} else {
					strength = t('edit_profile.password_strength_ok')
				}
			} else {
				strength = t('edit_profile.password_strength_weak')
			}
		} else {
			strength = t('edit_profile.password_strength_too_short')
		}
		setPasswordStrength(strength)
	}

	// Handle description changes and character count
	const handleDescriptionChange = e => {
		const input = e.target.value
		if (input.length <= 185) {
			setDescription(input)
			setCharCount(input.length)
		}
	}

	// Generate random avatar
	const generateRandomAvatar = () => {
		const randomSeed = Math.random().toString(36).substring(2, 15)
		const avatarUrl = `https://api.dicebear.com/9.x/thumbs/svg?seed=${randomSeed}`
		setProfilePicture(avatarUrl)
	}

	// New: Check username availability
	const checkUsernameAvailability = async name => {
		try {
			const { data } = await axiosInstance.get('/auth/check-username', { params: { name } })
			// If the new name is the same as the current user's name, it's available
			if (name === user.name) {
				setNameAvailable(true)
			} else {
				setNameAvailable(!data.exists)
			}
		} catch (error) {
			console.error(t('edit_profile.username_check_error'), error)
			setNameAvailable(false) // Assume not available on error
		}
	}

	// Handle username changes
	const handleNameChange = event => {
		const newName = event.target.value.slice(0, 16) // Ograniczenie długości
		setName(newName)
		if (nameError) {
			setNameError('')
		}

		// Frontend profanity check
		const containsForbiddenWord = customBlacklist.some(word => newName.toLowerCase().includes(word))
		if (leoProfanity.check(newName) || containsForbiddenWord) {
			setNameError(t('edit_profile.username_invalid'))
			setNameAvailable(false)
		} else {
			setNameError('')
			// If the name hasn't changed, it's available
			if (newName === user.name) {
				setNameAvailable(true)
			} else {
				// Check availability
				checkUsernameAvailability(newName)
			}
		}
	}

	// Handle password changes
	const handlePasswordChange = event => {
		const newPassword = event.target.value
		setPassword(newPassword)
		validatePassword(newPassword)
	}

	// Handle language changes
	const handleLanguageChange = selectedLang => {
		setLanguage(selectedLang)
		setIsDropdownOpen(false)
		i18n.changeLanguage(selectedLang)
	}

	// Close dropdown when clicking outside
	const handleClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsDropdownOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	// Handle form submission
	const submitHandler = async e => {
		e.preventDefault()

		// Disable form submission if there are frontend validation errors
		if (
			nameError ||
			!nameAvailable ||
			passwordStrength === t('edit_profile.password_strength_too_short') ||
			passwordStrength === t('edit_profile.password_strength_weak')
		) {
			setError(t('edit_profile.form_validation_error'))
			toast.error(t('edit_profile.form_validation_error'))
			return
		}

		try {
			const { data } = await axiosInstance.put('/auth/profile', {
				name,
				password,
				profilePicture,
				description,
				language, // Pass language
			})
			setUser(data) // Update user context
			localStorage.setItem('userInfo', JSON.stringify(data))
			// Set i18n language based on user preference
			i18n.changeLanguage(data.language || 'en')
			toast.success(t('edit_profile.profile_updated_success'))
			navigate('/chat')
		} catch (error) {
			console.error('Error updating profile', error)
			// Check if the error is due to username being taken
			if (error.response && error.response.data && error.response.data.message === 'Username is already taken') {
				setNameError(t('edit_profile.username_taken'))
				setNameAvailable(false)
				toast.error(t('edit_profile.username_taken'))
			} else {
				setError(t('edit_profile.profile_update_error'))
				toast.error(t('edit_profile.profile_update_error'))
			}
		}
	}

	// Handle account deletion
	const handleDeleteAccount = async () => {
		try {
			await axiosInstance.delete('/auth/delete-account')
			toast.success(t('edit_profile.account_deleted_success'))
			navigate('/login')
		} catch (error) {
			console.error('Error deleting account', error)
			toast.error(t('edit_profile.account_delete_error'))
		}
	}

	// Redirect to login if user is not authenticated
	if (!user) {
		navigate('/login')
		return null
	}

	// Determine if the form has any errors
	const hasErrors =
		!!error ||
		!!nameError ||
		!nameAvailable ||
		passwordStrength === t('edit_profile.password_strength_too_short') ||
		passwordStrength === t('edit_profile.password_strength_weak')

	return (
		<div className="edit-profile-container">
			<form className="edit-profile__form" onSubmit={submitHandler}>
				{error && <div className="edit-profile__error-message">{error}</div>}

				{/* Avatar */}

				<div className="edit-profile__picture-selection">
					<img src={profilePicture} alt="Profile" />
					<button type="button" className="edit-profile__button" onClick={generateRandomAvatar}>
						{t('edit_profile.change_avatar')}
					</button>
				</div>

				{/* Username */}
				<label className="edit-profile__label">{t('edit_profile.username_label')}</label>
				{nameError && <div className="edit-profile__error-message">{nameError}</div>}
				<div className="password-meter-div-parent">
					<input
						className={`edit-profile__input m-0 ${nameError || !nameAvailable ? 'edit-profile__input-error' : ''}`}
						type="text"
						value={name}
						onChange={handleNameChange}
						placeholder={t('edit_profile.username_placeholder')}
						maxLength={16} // Dodajemy ograniczenie do 16 znaków
					/>

					{/* Display availability error */}
					{!nameAvailable && !nameError && (
						<div className="edit-profile__error-message">{t('edit_profile.username_taken')}</div>
					)}
				</div>

				{/* Password */}
				<label className="edit-profile__label">
					{t('edit_profile.password_label')}
					<span className="edit-profile__label--optional">({t('edit_profile.optional')})</span>
				</label>
				<div className="password-meter-div-parent">
					<input
						className="edit-profile__input m-0"
						type="password"
						value={password}
						onChange={handlePasswordChange}
						placeholder={t('edit_profile.password_placeholder')}
					/>
					{password && passwordStrength && (
						<div className={`edit-profile__password-strength ${passwordStrength.replace(/ /g, '-').toLowerCase()}`}>
							{passwordStrength}
						</div>
					)}
				</div>

				{/* Description */}
				<label className="edit-profile__label">{t('edit_profile.description_label')}</label>
				<div className="edit-profile__textarea-wrapper">
					<textarea
						className="edit-profile__input"
						value={description}
						onChange={handleDescriptionChange}
						placeholder={t('edit_profile.description_placeholder')}
					/>
					<div className="edit-profile__char-count">{185 - charCount}</div>
				</div>

				{/* Language Selector */}
				<label className="edit-profile__label">{t('edit_profile.language_label')}</label>
				<div className="custom-dropdown" ref={dropdownRef}>
					<div className="custom-dropdown__selected" onClick={() => setIsDropdownOpen(prev => !prev)}>
						{/* Display flag next to selected language */}
						<span>
							{languages.find(lang => lang.code === language)?.flag}{' '}
							{languages.find(lang => lang.code === language)?.nativeName || t('edit_profile.select_language')}
						</span>
						<FaChevronDown className={`custom-dropdown__icon ${isDropdownOpen ? 'open' : ''}`} />
					</div>
					{isDropdownOpen && (
						<ul className="custom-dropdown__list">
							{languages.map(lang => (
								<li key={lang.code} className="custom-dropdown__item" onClick={() => handleLanguageChange(lang.code)}>
									{/* Display flag next to language name */}
									<span>
										{lang.flag} {lang.nativeName}
									</span>
								</li>
							))}
						</ul>
					)}
				</div>

				{/* Update Profile and Go Back Buttons */}
				<div className="buttons-grid-flex-x">
					<button className="xxx-xxx-222" type="submit" disabled={hasErrors}>
						{t('edit_profile.update_profile')}
					</button>
					<Link to="/chat" className="yyy">
						<button className="yyy" type="button">
							{t('edit_profile.go_back')}
						</button>
					</Link>
				</div>

				{/* Delete Account Button */}
				<div className="delete-account-section">
					<button type="button" className="delete-account-button" onClick={() => setIsConfirmationOpen(true)}>
						{t('edit_profile.delete_account')}
					</button>
				</div>
			</form>

			{/* Confirmation Dialog */}
			{isConfirmationOpen && (
				<ConfirmationDialog
					message={t('edit_profile.delete_account_confirmation')}
					onConfirm={() => {
						handleDeleteAccount()
						setIsConfirmationOpen(false)
					}}
					onCancel={() => setIsConfirmationOpen(false)}
				/>
			)}
		</div>
	)
}

export default EditProfile
