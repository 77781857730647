// src/components/UI/ConfirmationDialog/ConfirmationDialog.js
import React from 'react'
import { useTranslation } from 'react-i18next'
import './ConfirmationDialog.css'

const ConfirmationDialog = ({ message, onConfirm, onCancel }) => {
	const { t } = useTranslation()

	return (
		<div className="confirmation-dialog__overlay">
			<div className="confirmation-dialog__content">
				<p>{message}</p>
				<div className="confirmation__dialog__button__container">
					<button className="confirmation-dialog__button" onClick={onConfirm}>
						{t('confirmation_dialog.yes')}
					</button>
					<button className="confirmation-dialog__button" onClick={onCancel}>
						{t('confirmation_dialog.no')}
					</button>
				</div>
			</div>
		</div>
	)
}

export default ConfirmationDialog
