// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import i18n from '../i18n' // Import pliku i18n

const AuthContext = createContext()
axios.defaults.withCredentials = true

const axiosInstance = axios.create({
	baseURL: `https://findalike.me/api`,
	withCredentials: true,
})

let isRefreshing = false
let failedQueue = []

const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null)
	const [accessTokenExpiration, setAccessTokenExpiration] = useState(null)
	const navigate = useNavigate()
	const [isLoggingOut, setIsLoggingOut] = useState(false) // Nowa flaga

	useEffect(() => {
		const userInfo = localStorage.getItem('userInfo')
		if (userInfo) {
			const parsedUserInfo = JSON.parse(userInfo)
			setUser(parsedUserInfo)
			setAccessTokenExpiration(parsedUserInfo.accessTokenExpiration)
			// Ustawienie języka i18n na podstawie preferencji użytkownika
			i18n.changeLanguage(parsedUserInfo.language || 'en')
		}
	}, [])

	const logoutAndRedirect = () => {
		if (isLoggingOut) return // Zapobiega wielokrotnemu wywoływaniu

		setIsLoggingOut(true)
		const userInfo = JSON.parse(localStorage.getItem('userInfo'))
		if (!userInfo) {
			setUser(null)
			setAccessTokenExpiration(null)
			localStorage.removeItem('userInfo')
			i18n.changeLanguage('en') // Resetowanie języka do domyślnego
			setIsLoggingOut(false)
			return
		}

		axiosInstance
			.post('/auth/logout', {}, { headers: { Authorization: `Bearer ${userInfo.token}` } })
			.catch(error => {
				if (process.env.NODE_ENV !== 'production') {
					console.error('Błąd podczas wylogowywania:', error.response?.data || error.message)
				}
			})
			.finally(() => {
				setUser(null)
				setAccessTokenExpiration(null)
				localStorage.removeItem('userInfo')
				i18n.changeLanguage('en') // Resetowanie języka do domyślnego
				setIsLoggingOut(false)
			})
	}

	const login = async (email, password) => {
		try {
			const { data } = await axiosInstance.post('/auth/login', { email, password })
			setUser(data)
			setAccessTokenExpiration(data.accessTokenExpiration)
			localStorage.setItem('userInfo', JSON.stringify(data))
			// Ustawienie języka i18n na podstawie preferencji użytkownika
			i18n.changeLanguage(data.language || 'en')
			navigate(data.isVerified ? '/chat' : '/verify')
		} catch (error) {
			if (
				error.response &&
				error.response.status === 400 &&
				error.response.data.message === 'Niepoprawny email lub hasło'
			) {
				throw new Error('Nieprawidłowy email lub hasło')
			} else {
				throw new Error('Wystąpił problem. Spróbuj ponownie później.')
			}
		}
	}

	const register = async (name, email, password, profilePicture, language) => {
		// Dodany parametr language
		try {
			const { data } = await axiosInstance.post('/auth/register', {
				name,
				email,
				password,
				profilePicture,
				language, // Przesyłanie języka
			})
			setUser(data)
			setAccessTokenExpiration(data.accessTokenExpiration)
			localStorage.setItem('userInfo', JSON.stringify(data))
			// Ustawienie języka i18n na podstawie preferencji użytkownika
			i18n.changeLanguage(data.language || 'en')
			navigate(data.isVerified ? '/chat' : '/verify')
		} catch (error) {
			throw new Error('Rejestracja nie powiodła się. Spróbuj ponownie.')
		}
	}

	const logout = () => {
		logoutAndRedirect()
	}

	const refreshTokenFunc = async () => {
		const userInfo = JSON.parse(localStorage.getItem('userInfo'))
		const userId = userInfo ? userInfo._id : null
		if (userId) {
			try {
				const { data } = await axiosInstance.post('/auth/refresh-token', { userId }, { withCredentials: true })
				if (data.message === 'invalid_refresh_token') {
					logoutAndRedirect()
				} else {
					setAccessTokenExpiration(data.accessTokenExpiration)
					const updatedUserInfo = { ...userInfo, accessTokenExpiration: data.accessTokenExpiration }
					localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo))
					// Aktualizacja języka i18n
					i18n.changeLanguage(updatedUserInfo.language || 'en')
				}
			} catch (refreshError) {
				logoutAndRedirect()
			}
		} else {
			logoutAndRedirect()
		}
	}

	const refreshAuthLogic = async (failedRequest, userId) => {
		try {
			const { data } = await axiosInstance.post('/auth/refresh-token', { userId }, { withCredentials: true })

			if (data.message === 'invalid_refresh_token') {
				logoutAndRedirect()
				return Promise.reject(
					new Error('Użytkownik został wylogowany z powodu braku lub nieważnego tokenu odświeżania.')
				)
			}

			setAccessTokenExpiration(data.accessTokenExpiration)
			const userInfo = JSON.parse(localStorage.getItem('userInfo'))
			const updatedUserInfo = {
				...userInfo,
				accessTokenExpiration: data.accessTokenExpiration,
				language: data.language,
			} // Aktualizacja języka
			localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo))
			// Aktualizacja języka i18n
			i18n.changeLanguage(updatedUserInfo.language || 'en')

			failedQueue.forEach(req => req.resolve())
			failedQueue = []
			return axiosInstance(failedRequest)
		} catch (refreshError) {
			failedQueue.forEach(req => req.reject(refreshError))
			failedQueue = []

			if (refreshError.response && refreshError.response.status === 401) {
				logoutAndRedirect()
			}

			throw refreshError
		}
	}

	axiosInstance.interceptors.response.use(
		response => response,
		async error => {
			const originalRequest = error.config
			const userInfo = JSON.parse(localStorage.getItem('userInfo'))
			const userId = userInfo ? userInfo._id : null

			if (error.response && error.response.status === 401) {
				if (error.response.data.message === 'invalid_refresh_token') {
					logoutAndRedirect()
					return Promise.reject(error)
				}

				if (!originalRequest._retry) {
					originalRequest._retry = true

					if (userId) {
						if (!isRefreshing) {
							isRefreshing = true
							try {
								await refreshAuthLogic(originalRequest, userId)
								isRefreshing = false
								return axiosInstance(originalRequest)
							} catch (refreshError) {
								isRefreshing = false
								logoutAndRedirect()
								return Promise.reject(refreshError)
							}
						} else {
							return new Promise((resolve, reject) => {
								failedQueue.push({ resolve, reject })
							})
						}
					} else {
						logoutAndRedirect()
						return Promise.reject(error)
					}
				} else {
					logoutAndRedirect()
					return Promise.reject(error)
				}
			}

			return Promise.reject(error)
		}
	)

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				login,
				register,
				logout,
				refreshToken: refreshTokenFunc,
				logoutAndRedirect,
				axiosInstance,
			}}>
			{children}
		</AuthContext.Provider>
	)
}

export { AuthProvider, axiosInstance }
export const useAuth = () => useContext(AuthContext)
