// src/components/Auth/Register/Register.js
import React, { useState, useEffect, useRef } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './Register.css'
import { ReactComponent as ShowIcon } from '../../../assets/eye-solid.svg'
import { ReactComponent as HideIcon } from '../../../assets/eye-slash-solid.svg'
import { toast } from 'react-toastify'
import leoProfanity from 'leo-profanity'
import { useTranslation } from 'react-i18next'
import { FaChevronDown } from 'react-icons/fa' // Import ikony chevron

const Register = () => {
	const { t, i18n } = useTranslation()
	const navigate = useNavigate()
	const { register } = useAuth()
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [profilePicture, setProfilePicture] = useState('')
	const [error, setError] = useState('')
	const [nameAvailable, setNameAvailable] = useState(null)
	const [emailError, setEmailError] = useState(null)
	const [passwordError, setPasswordError] = useState('')
	const [passwordTouched, setPasswordTouched] = useState(false)
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [passwordMatch, setPasswordMatch] = useState(true)
	const [nameErrorState, setNameErrorState] = useState('')
	const [language, setLanguage] = useState('pl') // Domyślny język ustawiony na polski
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const dropdownRef = useRef(null)

	// Definicja języków z flagami
	const languages = [
		{ code: 'pl', nativeName: 'Polski', flag: '🇵🇱' },
		{ code: 'en', nativeName: 'English', flag: '🇬🇧' },
		{ code: 'fr', nativeName: 'Français', flag: '🇫🇷' },
		{ code: 'es', nativeName: 'Español', flag: '🇪🇸' },
	]

	// Lista zabronionych słów
	const customBlacklist = ['kurwa']

	// Inicjalizacja czarnej listy wulgaryzmów
	useEffect(() => {
		leoProfanity.loadDictionary()
		customBlacklist.forEach(word => leoProfanity.add(word))
	}, [])

	// Generowanie URL avatara przy zmianie nazwy
	useEffect(() => {
		if (name) {
			const avatarUrl = `https://api.dicebear.com/9.x/thumbs/svg?seed=${encodeURIComponent(name)}`
			setProfilePicture(avatarUrl)
		}
	}, [name])

	// Walidacja emaila
	useEffect(() => {
		validateEmail(email)
	}, [email])

	// Walidacja hasła
	useEffect(() => {
		validatePassword(password)
	}, [password])

	// Walidacja dopasowania hasła
	useEffect(() => {
		validatePasswordMatch()
	}, [password, confirmPassword])

	// Zamknięcie dropdown po kliknięciu poza nim
	useEffect(() => {
		const handleClickOutside = event => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsDropdownOpen(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	// Sprawdzanie dostępności nazwy użytkownika
	const checkUsernameAvailability = async name => {
		try {
			const { data } = await axios.get(`https://findalike.me/api/auth/check-username`, {
				params: { name },
			})
			setNameAvailable(!data.exists)
		} catch (error) {
			console.error(t('register.username_check_error'), error)
			setNameAvailable(null)
		}
	}

	// Walidacja emaila
	const validateEmail = async email => {
		if (email.trim() === '') {
			setEmailError(null)
			return
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (emailRegex.test(email)) {
			try {
				const { data } = await axios.get(`https://findalike.me/api/auth/check-email`, {
					params: { email },
				})
				if (data.exists) {
					setEmailError(t('register.email_taken'))
				} else {
					setEmailError(null)
				}
			} catch (error) {
				console.error(t('register.email_verification_error'), error)
				setEmailError(t('register.email_verification_error'))
			}
		} else {
			setEmailError(t('register.invalid_email'))
		}
	}

	// Walidacja hasła
	const validatePassword = password => {
		const minLength = 6
		const hasUppercase = /[A-Z]/.test(password)
		const hasNumber = /\d/.test(password)

		if (password.length >= minLength && hasUppercase && hasNumber) {
			setPasswordError('') // Brak błędu
		} else {
			setPasswordError(t('register.password_invalid'))
		}
	}

	// Walidacja dopasowania hasła
	const validatePasswordMatch = () => {
		setPasswordMatch(password === confirmPassword)
	}

	// Przełączanie widoczności hasła
	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible)
	}

	// Obsługa zmiany nazwy użytkownika
	const handleNameChange = event => {
		const newName = event.target.value.slice(0, 16) // Ograniczenie długości
		setName(newName)

		const containsForbiddenWord = customBlacklist.some(word => newName.toLowerCase().includes(word))

		if (leoProfanity.check(newName) || containsForbiddenWord) {
			setNameErrorState(t('register.username_invalid'))
		} else {
			setNameErrorState('')
			checkUsernameAvailability(newName)
		}
	}

	// Obsługa zmiany języka
	const handleLanguageChange = selectedLang => {
		setLanguage(selectedLang)
		setIsDropdownOpen(false)
		i18n.changeLanguage(selectedLang)
	}

	// Sprawdzanie czy formularz jest poprawny
	const isFormValid = () => {
		return (
			name &&
			email &&
			password &&
			confirmPassword &&
			!nameErrorState &&
			!emailError &&
			passwordMatch &&
			!passwordError &&
			nameAvailable !== false &&
			language
		)
	}

	// Obsługa przesłania formularza
	const submitHandler = async e => {
		e.preventDefault()

		// Upewnienie się, że język ma domyślną wartość, jeśli nie został zmieniony
		const selectedLanguage = language || 'pl'

		if (!passwordMatch) {
			const errorMsg = t('register.passwords_not_matching_error')
			setError(errorMsg)
			toast.error(errorMsg)
			return
		}
		if (nameAvailable === false) {
			const errorMsg = t('register.username_taken_error')
			setError(errorMsg)
			toast.error(errorMsg)
			return
		}
		if (emailError) {
			setError(emailError)
			toast.error(emailError)
			return
		}
		if (nameErrorState) {
			setError(nameErrorState)
			toast.error(nameErrorState)
			return
		}
		if (passwordError) {
			setError(passwordError)
			toast.error(passwordError)
			return
		}
		try {
			await register(name, email, password, profilePicture, selectedLanguage) // Przekazanie języka
			toast.success(t('register.registration_success'))
			navigate('/login') // Przekierowanie po rejestracji
		} catch (error) {
			setError(t('register.registration_error'))
			toast.error(t('register.registration_error'))
		}
	}

	return (
		<div className="register__auth">
			<div className="register__image-auth">
				<div className="register__image-auth-shadow"></div>
			</div>
			<div className="register__form-auth">
				<h1>{t('register.title')}</h1>
				<form className="register__form" onSubmit={submitHandler}>
					{error && <div className="register__error-message">{error}</div>}

					{/* Nazwa użytkownika */}
					<div className="register__input-container">
						{nameErrorState && <div className="register__error-message">{nameErrorState}</div>}
						<input
							className={`register__input ${name.length >= 16 ? 'input-max-reached' : ''} ${
								nameErrorState ? 'register__input-error' : ''
							}`}
							type="text"
							value={name}
							onChange={handleNameChange}
							placeholder={t('register.username_placeholder')}
							maxLength={16}
						/>

						{nameAvailable === false && (
							<div className="register__error-message">{t('register.username_taken_error')}</div>
						)}
					</div>

					{/* Email */}
					<div className="register__input-container">
						<input
							className={`register__input ${emailError ? 'register__input-error' : ''}`}
							type="email"
							value={email}
							onChange={e => setEmail(e.target.value)}
							placeholder={t('register.email_placeholder')}
						/>
						{emailError && <div className="register__error-message">{emailError}</div>}
					</div>

					{/* Hasło */}
					<div className="register__input-container ">
						<div className="register__input-container-child">
							<input
								className="register__input"
								type={passwordVisible ? 'text' : 'password'}
								value={password}
								onChange={e => setPassword(e.target.value)}
								onFocus={() => setPasswordTouched(true)}
								placeholder={t('register.password_placeholder')}
							/>
							<div className="register__password-toggle-icon" onClick={togglePasswordVisibility}>
								{passwordVisible ? <ShowIcon width="19px" /> : <HideIcon width="20px" />}
							</div>
						</div>
						{passwordTouched && passwordError && <div className="register__error-message">{passwordError}</div>}
					</div>

					{/* Potwierdzenie hasła */}
					<div className="register__input-container">
						<input
							className={`register__input ${!passwordMatch ? 'register__input-error' : ''}`}
							type="password"
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
							placeholder={t('register.confirm_password_placeholder')}
						/>
						{confirmPassword && !passwordMatch && (
							<div className="register__error-message">{t('register.passwords_not_matching_error')}</div>
						)}
					</div>

					{/* Przełącznik języka */}
					<div className="custom-dropdown register-dropdown" ref={dropdownRef}>
						<div className="custom-dropdown__selected" onClick={() => setIsDropdownOpen(prev => !prev)}>
							<span>
								{languages.find(lang => lang.code === language)?.flag}{' '}
								{languages.find(lang => lang.code === language)?.nativeName || 'Select Language'}
							</span>
							<FaChevronDown className={`custom-dropdown__icon ${isDropdownOpen ? 'open' : ''}`} />
						</div>
						{isDropdownOpen && (
							<ul className="custom-dropdown__list">
								{languages.map(lang => (
									<li key={lang.code} className="custom-dropdown__item" onClick={() => handleLanguageChange(lang.code)}>
										<span>
											{lang.flag} {lang.nativeName}
										</span>
									</li>
								))}
							</ul>
						)}
					</div>

					{/* Przycisk rejestracji */}
					<button className="register__button" type="submit" disabled={!isFormValid()}>
						{t('register.submit_button')}
					</button>
				</form>
				<p>
					{t('register.already_have_account')}{' '}
					<span>
						<Link to="/login">{t('register.login')}</Link>
					</span>
				</p>
			</div>
		</div>
	)
}

export default Register
