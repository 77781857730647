// src/components/Auth/Login/Login.js
import React, { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link } from 'react-router-dom'
import './Login.css'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const Login = () => {
	const { t } = useTranslation()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState('')
	const [emailValid, setEmailValid] = useState(true)
	const [passwordValid, setPasswordValid] = useState(true)

	const { login } = useAuth()

	const validateEmail = email => {
		const re = /\S+@\S+\.\S+/
		return re.test(email)
	}

	const validatePassword = password => {
		return password.length >= 6
	}

	const submitHandler = async e => {
		e.preventDefault()

		const isEmailValid = validateEmail(email)
		const isPasswordValid = validatePassword(password)

		setEmailValid(isEmailValid)
		setPasswordValid(isPasswordValid)

		if (!isEmailValid || !isPasswordValid) {
			const errorMsg = t('login.invalid_data')
			setError(errorMsg)
			toast.error(errorMsg)
			return
		}

		try {
			await login(email, password)
			toast.success(t('login.success'))
		} catch (error) {
			if (error.message === 'Nieprawidłowy email lub hasło' || error.message === 'Invalid email or password') {
				const errorMsg = t('login.invalid_credentials')
				setError(errorMsg)
				toast.error(errorMsg)
			} else {
				setError(error.message)
				toast.error(error.message)
			}
		}
	}

	const isButtonDisabled = !emailValid || !passwordValid || !email || !password

	return (
		<div className="login__auth">
			<div className="login__image-auth">
				<div className="login__image-auth-shadow"></div>
			</div>
			<div className="login__form-auth">
				<h1>{t('login.title')}</h1>
				<form className="login__form" onSubmit={submitHandler}>
					{error && (
						<div className="login__error-message">
							<span>{error}</span>
						</div>
					)}
					<div className="login__input-container">
						<input
							className={`login__input ${!emailValid ? 'login__input-error' : ''}`}
							type="email"
							value={email}
							onChange={e => setEmail(e.target.value)}
							placeholder={t('login.email_placeholder')}
						/>
						{!emailValid && email && (
							<div className="login__error-message">
								<span>{t('login.invalid_email_format')}</span>
							</div>
						)}
					</div>

					<div className="login__input-container">
						<input
							className={`login__input ${!passwordValid ? 'login__input-error' : ''}`}
							type="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
							placeholder={t('login.password_placeholder')}
						/>
						{!passwordValid && password && <div className="login__error-message">{t('login.password_min_length')}</div>}
					</div>

					<button className="login__button" type="submit" disabled={isButtonDisabled}>
						{t('login.button')}
					</button>
				</form>

				<p className="login__text__other">
					{t('login.no_account')}{' '}
					<span>
						<Link to="/register">{t('login.register')}</Link>
					</span>
				</p>
			</div>
		</div>
	)
}

export default Login
